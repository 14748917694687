.bg-blue{
  background-color:#25a3cc;
}

a {
  color:#25a3cc;
}

a:hover{
  text-decoration:none;
  color:#25a3cc;
  font-weight:bold;
}

.logo{
  height:40px;
  margin-right:10px;
}

.logo-icon{
  height:70px;
}

.btn-primary{
  background:#25a3cc !important;
  border:1px #DDD solid !important;
}
